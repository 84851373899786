import React, { useState, useEffect } from "react";

const ProgressBar = (props) => {
  const [isVisible, setIsVisible] = useState(0);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  useEffect(() => {
    const amountList = props.milestones.map((value) => {
      return {
        amount: value.amount.value,
      };
    });

    let result = amountList.map((a) => a.amount);

    const nextNumber = (element) => element > props.raised;

    setIsVisible(result.findIndex(nextNumber));
  }, [props.milestones, props.raised]);

  const toggle = (i) => {
    if (isVisible === i) setIsVisible(i);
    else setIsVisible(i);
  };

  return (
    <div className="progress_wrapper">
      <section className="inner-row gutters constrain overlay_lp__content progress">
        <div className="col-1"></div>
        <div className="col-10">
          <div className="progress_wrapper__text">
            <p>The Relay Community raised...</p>
          </div>
          <span className="progress_wrapper__raised">
            {formatter.format(props.raised).replace(".00", "")} Raised
          </span>
          <span className="progress_wrapper__goal">
            Goal: {formatter.format(props.goal).replace(".00", "")}
          </span>
          <div className="progress_wrapper__therm">
            <div
              className="progress_wrapper__therm__inner"
              style={{
                width: `${props.progress + 0.5}%`,
              }}
            ></div>
            <div className="milestones">
              <ul>
                {props.milestones.map((milestone, i) => (
                  <li
                    onClick={() => toggle(i)}
                    key={milestone.id}
                    index={i}
                    className={`${
                      Number(100 * (milestone.amount.value / props.goal)) >
                      props.progress
                        ? "milestone higher"
                        : "milestone lower"
                    }`}
                    style={{
                      left: `${
                        Number(
                          100 * (milestone.amount.value / Number(props.goal))
                        ) === 100
                          ? 99
                          : Math.ceil(
                              100 *
                                (milestone.amount.value / Number(props.goal))
                            ) - 1
                      }%`,
                    }}
                  ></li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
        <div className="col-12 milestone_title_wrapper">
          {props.milestones.map((milestone, i) => (
            <div
              key={milestone.id}
              className={isVisible === i ? "show" : "hide"}
            >
              <div className="milestone_header">
                {milestone.amount.value < props.raised
                  ? "Milestone Completed"
                  : "Upcoming Milestone"}
              </div>
              <div className="milestone_title">
                <div>
                  ${formatNumber(milestone.amount.value)} - {milestone.name}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ProgressBar;
