import React, { useEffect, useState, Fragment, useRef } from "react";

// import myke from "../../images/myke-heade.png";
// import stephen from "../../images/stephen-head.png";

import myke from "../../images/myke_idle.gif";
import stephen from "../../images/stephen_idle.gif";
import mykeStanding from "../../images/myke_standing.png";
import stephenStanding from "../../images/stephen_standing.png";
import mykeJump from "../../images/myke_jump.gif";
import stephenJump from "../../images/stephen_jump.gif";

const Scoreboard = (props) => {
  const [stephenPoints, setStephenPoints] = useState(0);
  const [mykePoints, setMykePoints] = useState(0);
  const [stephenCelebrate, setStephenCelebrate] = useState(false);
  const [mykeCelebrate, setMykeCelebrate] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      fetch(process.env.REACT_APP_APIGATEWAY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ function: "scan" }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          // console.log(
          //   "data 0:",
          //   data.Items[0].id,
          //   data.Items[0].person,
          //   data.Items[0].score,
          //   "data 1:",
          //   data.Items[1].id,
          //   data.Items[1].person,
          //   data.Items[1].score
          // );
          setStephenPoints(data.Items[1].score);
          setMykePoints(data.Items[0].score);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, 5000);

    return () => clearInterval(timer); //This is important
  }, []);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const animationTimer = () => {
    setAnimate(false);
    setTimeout(() => {
      setAnimate(true);
      setTimeout(animationTimer, 5000);
    }, 30000);
  };

  useEffect(() => {
    setTimeout(animationTimer, 5000);
  }, []);

  const prevStephenPoints = usePrevious(stephenPoints);
  const prevMykePoints = usePrevious(mykePoints);

  useEffect(() => {
    if (prevStephenPoints <= stephenPoints) {
      setStephenCelebrate(true);
      setTimeout(() => {
        setStephenCelebrate(false);
      }, 5000);
    }
  }, [stephenPoints, prevStephenPoints]);

  useEffect(() => {
    if (prevMykePoints <= mykePoints) {
      setMykeCelebrate(true);
      setTimeout(() => {
        setMykeCelebrate(false);
      }, 5000);
    }
  }, [mykePoints, prevMykePoints]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const size = urlParams.get("size");

  return (
    <Fragment>
      {props.size === "page" ? (
        <div
          className={
            size === "small"
              ? "scoreboard-score small"
              : props.size === "page"
              ? "scoreboard-score page"
              : "scoreboard-score big"
          }
        >
          <div className="head_wrapper">
            <div className="stephen">
              {/* <img src={myke} alt="myke head" /> */}
              <p className="page_points">{stephenPoints}</p>
            </div>
            <div className="myke">
              {/* <img src={stephen} alt="stephen head" /> */}
              <p className="page_points">{mykePoints}</p>
            </div>
          </div>
        </div>
      ) : size === "small" ? (
        <div className="scoreboard-score small">
          <div className="video-wrapper">
            <video playsInline autoPlay muted loop id="bgvid">
              <source
                src="https://playlive.experience.stjude.org/animated_background.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div
            className={
              "score-wrapper" +
              `${mykePoints >= 100 || stephenPoints >= 100 ? " triple" : ""}`
            }
          >
            <div className="score stephen">
              {/* <p className="name">Myke</p> */}
              <p className="points">{stephenPoints}</p>
            </div>
            <div className="score myke">
              {/* <p className="name">Stephen</p> */}
              <p className="points">{mykePoints}</p>
            </div>
          </div>
          <div className="fight-wrapper">
            {stephenCelebrate ? (
              <div className="fight stephen jump">
                <img src={stephenJump} alt="stephen" />
              </div>
            ) : (
              <div className="fight stephen">
                {animate ? (
                  <img src={stephen} alt="stephen" />
                ) : (
                  <img src={stephenStanding} alt="stephen" />
                )}
              </div>
            )}

            {mykeCelebrate ? (
              <div className="fight myke jump">
                <img src={mykeJump} alt="myke" />
              </div>
            ) : (
              <div className="fight myke">
                {animate ? (
                  <img src={myke} alt="myke" />
                ) : (
                  <img src={mykeStanding} alt="myke" />
                )}
              </div>
            )}
          </div>
        </div>
      ) : size === "large" ? (
        <div className="scoreboard-score large">
          <div className="video-wrapper">
            <video playsInline autoPlay muted loop id="bgvid">
              <source
                src="https://playlive.experience.stjude.org/animated_background.mp4"
                type="video/mp4"
              />
            </video>
          </div>

          <div className="header">
            <h1>Relay FM</h1>
            <p>Podcastathon 2023</p>
          </div>
          <div
            className={
              "score-wrapper" +
              `${mykePoints >= 100 || stephenPoints >= 100 ? " triple" : ""}`
            }
          >
            <div className="score stephen">
              {/* <p className="name">Myke</p> */}
              <p className="points">{stephenPoints}</p>
            </div>
            <div className="score myke">
              {/* <p className="name">Stephen</p> */}
              <p className="points">{mykePoints}</p>
            </div>
          </div>
          <div className="fight-wrapper">
            {stephenCelebrate ? (
              <div className="fight stephen jump">
                <img src={stephenJump} alt="stephen" />
              </div>
            ) : (
              <div className="fight stephen">
                {animate ? (
                  <img src={stephen} alt="stephen" />
                ) : (
                  <img src={stephenStanding} alt="stephen" />
                )}
              </div>
            )}
            {/* <div className="fight stephen">
              <img src={stephen} alt="stephen" />
            </div> */}
            {/* <div className="fight stephen jump">
              <img src={stephenJump} alt="stephen" />
            </div> */}
            {/* <div className="verse">VS</div> */}
            {mykeCelebrate ? (
              <div className="fight myke jump">
                <img src={mykeJump} alt="myke" />
              </div>
            ) : (
              <div className="fight myke">
                {animate ? (
                  <img src={myke} alt="myke" />
                ) : (
                  <img src={mykeStanding} alt="myke" />
                )}
              </div>
            )}
            {/* <div className="fight myke">
              <img src={myke} alt="myke" />
            </div> */}
            {/* <div className="fight myke jump">
              <img src={mykeJump} alt="myke" />
            </div> */}
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Scoreboard;
