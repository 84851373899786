import React from "react";

const Leaderboard = (props) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  return (
    <div className="inner-row gutters constrain overlay_lp__content leaderboard">
      <div className="col-12">
        <h2>Top Fundraisers</h2>
      </div>
      <div className="col-4">
        {props.leaderboard.slice(0, 17).map((individual) => (
          <div className="leaderboard_wrapper" key={individual.id}>
            <div className="leaderboard_wrapper__name">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://tiltify.com` + individual.url}
              >
                {individual.name}
              </a>
            </div>
            <div className="leaderboard_wrapper__amount">
              {/* {Number(individual.amount.value)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                .replace(/\.00$/, "")} */}
              {formatter.format(individual.amount.value).replace(".00", "")}
            </div>
          </div>
        ))}
      </div>
      <div className="col-4">
        {props.leaderboard.slice(17, 34).map((individual) => (
          <div className="leaderboard_wrapper" key={individual.id}>
            <div className="leaderboard_wrapper__name">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://tiltify.com` + individual.url}
              >
                {individual.name}
              </a>
            </div>
            <div className="leaderboard_wrapper__amount">
              {formatter.format(individual.amount.value).replace(".00", "")}
            </div>
          </div>
        ))}
      </div>
      <div className="col-4">
        {props.leaderboard.slice(34, 52).map((individual) => (
          <div className="leaderboard_wrapper" key={individual.id}>
            <div className="leaderboard_wrapper__name">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://tiltify.com` + individual.url}
              >
                {truncateString(individual.name, 20)}
              </a>
            </div>
            <div className="leaderboard_wrapper__amount">
              {formatter.format(individual.amount.value).replace(".00", "")}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leaderboard;
